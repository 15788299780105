// test

<template>
  	<v-container fluid pa-0>
		<v-card tile>
		<v-toolbar dense flat color="blue-grey darken-3">
			<v-text-field flat solo dense hide-details background-color="blue-grey darken-3" prefix="Kunde:" readonly
				:value="'Max Mustermann'"
			></v-text-field>  
        	<v-spacer />
			<v-btn disabled plain small>ID: {{ 31 }}</v-btn>
			<v-btn ripple icon><v-icon color="yellow darken-3">mdi-star</v-icon></v-btn>
		</v-toolbar>
		<v-tabs v-model="myacttab" background-color="blue-grey darken-3" color="grey" active-class="blue-grey darken-1 black--text"
			align-with-title
			slider-color="blue-grey darken-1"
			dark dense height="35px"
			next-icon="mdi-arrow-right-bold-box-outline"
			prev-icon="mdi-arrow-left-bold-box-outline"
			show-arrows show-arrows-on-hover center-active
		>
			<v-tab v-for="(mytab, index) in mytabs" :key="index">
				{{ mytab.name }}
			</v-tab>
		</v-tabs>
			<v-toolbar flat color="blue-grey darken-1" v-if="mytabs[myacttab].name!='Grunddaten'">
				<v-text-field v-model="search" flat solo dense hide-details background-color="blue-grey darken-2" 
				prepend-inner-icon="search" append-icon="mdi-keyboard-return" clearable @click:clear="onSearchClear()"></v-text-field>  
				<v-btn class="mx-1 mt-0" color="blue-grey darken-4" @click.stop="onSearch()">Suchen</v-btn>
				<v-spacer></v-spacer>
				<v-spacer></v-spacer>
				<v-btn color="blue-grey darken-4" @click.stop="onEditItem()">Neu</v-btn>
			</v-toolbar>
			<v-tabs-items v-model="myacttab">
			<v-tab-item>
      			<v-card class="elevation-0 blue-grey darken-1" background-color="blue-grey darken-3">
          		<v-container fluid>
					<v-row>
						<v-col cols="6">
							<v-text-field prefix="First name:" flat solo dense hide-details readonly background-color="blue-grey darken-2" :value="'Max Mustermann'"
							></v-text-field>
						</v-col>
						<v-col cols="6">
							<v-text-field prefix="Last name:" flat solo dense hide-details readonly  background-color="blue-grey darken-2" :value="'Max Mustermann'"
							></v-text-field>
						</v-col>
					</v-row>
				  </v-container>
				</v-card>
			</v-tab-item>
			<v-tab-item class="secondary">
				<v-data-table dark
					:headers="headers" class="secondary"
					:items="items"
					item-class="truncate"
					:search="search" fixed-header height="300"
				>
					<template #item.action="{ item }">
						<v-icon color="amber darken-2" @click.stop="onShowItem(item)">remove_red_eye</v-icon>
						<v-icon color="green darken-2" @click.stop="onShowItem(item)">edit</v-icon>
						<v-icon color="grey lighten-2" @click.stop="onShowItem(item)">print</v-icon>
					</template>
				</v-data-table>
			</v-tab-item>
			</v-tabs-items>
		</v-card>
	</v-container>
</template>
<script>
export default {
	name: 'test',
	components: {
	},
	data () {
		return {
			search: '',
			mytabs: [{ name: 'Grunddaten', title: '' }, { name: 'Rechnungen', title: 'Rechnungen' }],
			myacttab: 0,
			headers: [
				{
					text: 'ID',
					value: 'id',
					align: 'right',
					filterable: false,
					sortable: true, width: '63px', divider: true, class: 'blue-grey darken-1 black--text',
				},
				{
					text: 'Spalte1',
					value: 'name1',
					align: 'left',
					filterable: false,
					sortable: false, width: '23px', divider: true, class: 'blue-grey darken-1 black--text',
				},
				{ text: 'Spalte2', value: 'name2', divider: true, class: 'blue-grey darken-1 black--text', },
				{ text: 'action', value: 'action', align: 'center', sortable: false, class: 'blue-grey darken-1 black--text', },
			],
			items: [
				{ id: 1, name1: 'Zeile1 Zeile1 Zeile1 Zeile1 Zeile1 Zeile1', name2: 'Zeile1', truncate: 'truncate-text',},
				{ id: 2, name1: 'Zeile2', name2: 'Zeile2', },
			],
			showItem: { name1: '', name2: '' },
		}
	},
	computed: {
		mytitle() {
			return this.mytabs[this.myacttab].title
		},
	},
	methods: {
		onSearch(){
			
		},
		onSearchClear(){
			
		},
		onEditItem(){

		},
		onShowItem(item){
			console.log(item)
			this.showItem = Object.assign({}, item)
		},
	},
};
</script>
<style>
.truncate-text {
  width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-text-field__prefix {
    color: rgba(255, 255, 255, 0.3);
}
</style>